animationParams($name, $duration, $ease, $delay=0s, $count=1, $dir=normal, $fill=none)
  animation-name: $name
  animation-duration: $duration
  animation-timing-function: $ease
  animation-delay: $delay
  animation-iteration-count: $count
  animation-direction: $dir
  animation-fill-mode: $fill
  animation-play-state: running

//[$name, $duration, $ease, $delay, $count, $dir, $fill]
animationParamsArray($arr)
  $params = ($arr[0][0]) ($arr[0][1]) ($arr[0][2]) ($arr[0][3]) ($arr[0][4]) ($arr[0][5]) ($arr[0][6])
  for $v, i in $arr
    if(i > 0)
      for j in 0 1 2 3 4 5 6
        $params[j] = "%s, %s" % ($params[j] $v[j])
  animation-name            : $params[0]
  animation-duration        : $params[1]
  animation-timing-function : $params[2]
  animation-delay           : $params[3]
  animation-iteration-count : $params[4]
  animation-direction       : $params[5]
  animation-fill-mode       : $params[6]
  animation-play-state      : running



updown($amount)
  @keyframes updown
    0%
      transform:translate3d(0, 0, 0)
    25%
      transform: "translate3d(0, %s, 0)" % $amount
    50%
      transform:translate3d(0, 0, 0)
    75%
      transform: "translate3d(0, %s, 0)" % ($amount*-1)
    100%
      transform:translate3d(0, 0, 0)

svgOffset($from, $to, $name="")
  @keyframes offset_{$name}
    from
      stroke-dashoffset: $from
    to
      stroke-dashoffset: $to

rotateanim($from, $to, $name="")
  $divide = 1
  $step = ($to - $from) / $divide
  @keyframes rotate_{$name}
    for $i in (0..$divide)
      $percent = ($i * 100 / $divide) + "%"
      {$percent}
        transform: "rotate3d(0, 0, 1, %sdeg)" % ($from + ($step * $i))

scaleanim($from, $to, $name)
  @keyframes scale_{$name}
    from
      transform: "scale(%s)" % $from
    to
      transform: "scale(%s)" % $to


heightanim($from, $to, $name="")
 @keyframes heightanim{$name}
  from
    height: $from
  to
    height: $to

widthanim($from, $to, $name="")
 @keyframes widthanim{$name}
  from
    width: $from
  to
    width: $to

leftanim($from, $to, $name="")
 @keyframes leftanim{$name}
  from
    left: $from
  to
    left: $to

fadeOpacity($from, $to, $name="")
  @keyframes fadeOpacity_{$name}
   from
     opacity: $from
   to
     opacity: $to

brightnessanim($from, $to, $name="")
  @keyframes brightness{$name}
    from
      filter: "brightness(%s)" % $from
    to
      filter: "brightness(%s)" % $to

yanim($from, $to, $name)
  @keyframes yanim_{$name}
    from
      transform: "translate3d(0, %spx, 0)" % $from
    to
      transform: "translate3d(0, %spx, 0)" % $to



 @keyframes blurAnim {
  0% { filter: none; }
  60% { filter: blur(4px); }
  100% { filter: none; }
}
