html,
body {
  border: 0;
  color: #000;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
html li,
body li {
  list-style-type: none;
}
* {
  box-sizing: border-box;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
a {
  color: #000;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
:focus {
  outline: 0;
}
span.upper {
  font-size: 10px;
  vertical-align: super;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
table,
img,
section,
header,
footer,
article,
object,
svg {
  position: relative;
  backface-visibility: visible;
  outline: none;
}
img,
canvas,
object,
svg {
  display: block;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}
hr {
  display: none;
}
svg {
  overflow: visible;
  padding: 1px;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}
@-moz-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@-webkit-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@-o-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Roboto/Roboto-Thin.ttf") format('truetype');
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/Roboto/Roboto-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Roboto/Roboto-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Light.eot");
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Regular.eot");
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Bold.eot");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPLight.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPLight.otf") format("opentype");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPRegular.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPRegular.otf") format("opentype");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPBold.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPBold.otf") format("opentype");
}
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Libre Baskerville Bold';
  font-style: bold;
  font-weight: 600;
  src: url("/fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf") format('truetype');
}
.pc {
  display: none !important;
}
body,
html {
  font-family: 'Roboto', 'YakuHanJP', 'NotoSansJP', sans-serif;
  font-weight: 100;
  width: 100%;
  height: 100%;
}
body {
  position: relative;
  outline: none;
  color: #000;
  background: #fff;
  text-align: left;
  line-height: 1.231;
  -webkit-text-size-adjust: none;
  letter-spacing: 2.5px;
}
#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999999;
  opacity: 1;
}
#loading .line {
  width: 0%;
  height: 2px;
  background-color: #ccc;
  top: calc(50% - 1px);
}
#loading .line.auto {
  width: 50%;
  animation-name: widthanimloading;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
@-moz-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@-webkit-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@-o-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
body.no-gl #wrap .page-container.page-top {
  background-color: #fff;
}
body.no-gl #wrap .page-container.page-top .enter {
  display: none;
}
body.no-gl #wrap .page-container.page-top nav.nav {
  width: 327px;
  height: 45px;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  display: block;
  top: 50%;
  left: 50%;
  padding: 0 22px;
  margin: 70px auto 0;
}
body.no-gl #wrap .page-container.page-top nav.nav ul li {
  display: inline-block;
  line-height: 42px;
  font-size: -1;
  letter-spacing: 0em;
}
body.no-gl #wrap .page-container.page-top nav.nav ul li a {
  letter-spacing: 2.5px;
  color: #000;
  font-size: 12px;
  font-weight: 400;
}
body.no-gl #wrap .page-container.page-top nav.nav ul li:nth-child(n+2) {
  margin-left: 10px;
}
body.no-gl #wrap .page-container.page-top .nogl-description {
  display: block;
  position: fixed;
  height: 66.5px;
  width: 100%;
  background: #e5e5e5;
  bottom: 0;
  text-align: center;
}
body.no-gl #wrap .page-container.page-top .nogl-description p {
  font-size: 13px;
  line-height: 18px;
  margin-top: 16px;
  color: #888;
}
.canvasMovieWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  color: #fff;
  background-size: cover;
  background-position: center center;
}
#VideoCanvasContainer img {
  height: 100vh;
}
#Video2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
}
#wrap .chapter .contentsWrap .seek {
  opacity: 0;
}
#wrap .chapter .contentsWrap .switch {
  opacity: 0;
  display: none;
}
#wrap .chapter .contentsWrap .backbutton {
  display: none;
  opacity: 0;
}
#wrap .offshot .contentsWrap .switch {
  opacity: 0;
  display: none;
}
#wrap .offshot .contentsWrap .seek {
  opacity: 0;
}
#wrap .page-container.page-top {
  position: fixed;
}
#wrap .page-container.page-top.enter {
  overflow: hidden;
}
#wrap .page-container {
  -webkit-overflow-scrolling: touch;
}
#wrap {
  position: relative;
  background-color: #ccc;
  color: #fff;
  width: 100%;
  height: 100%;
}
#wrap a {
  color: #fff;
}
#wrap #pageWrap {
  width: 100%;
  height: 100%;
}
#wrap .page-container {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
#wrap .enterWrap {
  position: absolute;
  top: 50%;
  margin-top: -210px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
#wrap .enterWrap .nogl-description {
  display: none;
}
#wrap .enterWrap nav.nav {
  display: none;
}
#wrap .enterWrap .logo {
  position: relative;
  text-indent: -9999px;
  width: 309.5px;
  height: 191.5px;
  margin: 0 auto;
  letter-spacing: 0;
  line-height: 0;
}
#wrap .enterWrap .logo svg {
  width: 100%;
  height: 100%;
}
#wrap .enterWrap .logo svg path {
  fill: #333;
}
#wrap .enterWrap .read {
  margin: 54px auto 0;
  width: 242px;
  text-align: center;
  color: #000;
  letter-spacing: 0.05em;
  font-size: 12px;
}
#wrap .enterWrap .read:before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 40px;
  height: 1px;
  background-color: #000;
  display: block;
}
#wrap .enterWrap .read:after {
  content: "";
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 40px;
  height: 1px;
  background-color: #000;
  display: block;
}
#wrap .enterWrap .enter {
  margin: 96px auto 0;
  width: 75px;
  height: 75px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#wrap .enterWrap .enter .txt {
  position: relative;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  z-index: 2;
  font-weight: 100;
  letter-spacing: 0.06em;
}
#wrap .enterWrap .enter .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#wrap .enterWrap .enter .bg svg {
  width: 100%;
  height: 100%;
}
#wrap .enterWrap .enter .bg circle {
  fill: #333;
  stroke: #333;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
}
#wrap .contentsWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: #000;
}
#wrap .contentsWrap .logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 90px;
  height: 46px;
  z-index: 100;
  text-indent: -9999px;
}
#wrap .contentsWrap .logo svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#wrap .contentsWrap .logo svg path {
  fill: #000;
}
#wrap .contentsWrap .switch {
  position: absolute;
  left: 50%;
  bottom: 24px;
  margin-left: -33px;
  z-index: 100;
}
#wrap .contentsWrap .switch a {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
#wrap .contentsWrap .switch .txt {
  position: relative;
  z-index: 2;
  width: 18px;
  height: 4px;
}
#wrap .contentsWrap .switch .txt span {
  position: absolute;
  background-color: #000;
  width: 4px;
  height: 4px;
}
#wrap .contentsWrap .switch .txt span:nth-of-type(1) {
  left: 0px;
}
#wrap .contentsWrap .switch .txt span:nth-of-type(2) {
  left: 7px;
}
#wrap .contentsWrap .switch .txt span:nth-of-type(3) {
  left: 14px;
}
#wrap .contentsWrap .switch .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#wrap .contentsWrap .switch .bg svg {
  width: 100%;
  height: 100%;
}
#wrap .contentsWrap .switch .bg circle {
  fill: transparent;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
  stroke: #000;
}
#wrap .contentsWrap .switch p {
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  margin-top: 8px;
  letter-spacing: 0.08em;
  transform: scale(0.9);
}
#wrap .contentsWrap .backbutton {
  top: calc(50% + 28vw + 27px - 44px);
  position: absolute;
  margin-top: 40px;
  width: 115px;
  left: 50%;
  margin-left: -41px;
  z-index: 100;
}
#wrap .contentsWrap .backbutton p {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.08em;
  position: relative;
  display: inline-block;
  width: 82px;
}
#wrap .contentsWrap .backbutton p:after {
  content: "";
  position: absolute;
  right: -30px;
  width: 11.5px;
  height: 12.5px;
  background-size: 100%;
  background-image: url("/svg/backbutton-arrow.svg");
}
#wrap .contentsWrap .seek {
  position: absolute;
  left: 10%;
  top: calc(50% + 28vw + 27px - 44px);
  width: 80%;
  height: 1px;
  z-index: 10;
  pointer-events: none;
}
#wrap .contentsWrap .seek .seekbar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.188);
  width: 100%;
  height: 1px;
}
#wrap .contentsWrap .seek .line {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  width: 30%;
  height: 1px;
}
#wrap .contentsWrap .seek .current {
  position: absolute;
  top: -3px;
  left: 30%;
  background-color: #000;
  width: 1px;
  height: 7px;
}
#wrap .contentsWrap .movieArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
#wrap .contentsWrap .movieArea.view {
  z-index: 2;
  color: #000;
}
#wrap .contentsWrap .movieArea.view .movieInner {
  width: 100%;
  height: 100%;
  padding-top: 50vh;
  text-align: center;
}
#wrap .contentsWrap .movieArea.thumb {
  color: #000;
  text-align: center;
}
#wrap .contentsWrap .movieArea.thumb .scroller {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}
#wrap .contentsWrap .movieArea.thumb a {
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .name {
  position: absolute;
  top: calc(50% - 38vw - 23px);
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 7;
}
#wrap .contentsWrap .movieArea.thumb .name .tit {
  font-size: 13px;
}
#wrap .contentsWrap .movieArea.thumb .name .tit .titleInner {
  width: 200px;
  display: inline-block;
}
#wrap .contentsWrap .movieArea.thumb .name .tit .arrow {
  display: inline-block;
  width: 15px;
  height: 8px;
  background: url("/svg/arrow-right.svg") no-repeat 0 0;
  background-size: auto 8px;
  margin-left: -15px;
  transform: translateX(28px);
}
#wrap .contentsWrap .movieArea.thumb .name .chapter {
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: bold;
  transform: scale(0.9);
}
#wrap .contentsWrap .movieArea.thumb .state {
  position: absolute;
  top: calc(50% + 22vw);
  left: 0;
  width: 100%;
  z-index: 7;
  margin-top: 40px;
}
#wrap .contentsWrap .movieArea.thumb .state .location {
  font-size: 13px;
}
#wrap .contentsWrap .movieArea.thumb .state .location .locationInner {
  width: 200px;
  display: inline-block;
}
#wrap .contentsWrap .movieArea.thumb .state .location .icon {
  display: inline-block;
  width: 23px;
  height: 19px;
  background: url("/img/icon_03.png") no-repeat 0 0;
  background-size: auto 19px;
  margin-bottom: -5px;
}
#wrap .contentsWrap .movieArea.thumb .state .location .arrow {
  display: inline-block;
  width: 15px;
  height: 8px;
  background: url("/svg/arrow-right.svg") no-repeat 0 0;
  background-size: auto 8px;
  margin-left: -15px;
  transform: translateX(28px);
}
#wrap .contentsWrap .movieArea.thumb .state .neWrap {
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: bold;
  transform: scale(0.9);
}
#wrap .contentsWrap .movieArea.thumb .state .neWrap>*:nth-child(2) {
  margin: 0 5px;
}
#wrap .contentsWrap .movieArea.thumb .movieInner {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
}
#wrap .contentsWrap .movieArea.thumb .movieInner .item {
  top: 50%;
  left: 5%;
  width: 90%;
  margin-top: calc(-19.03125vw);
  position: absolute;
}
#wrap .contentsWrap .movieArea.thumb .movieInner .item .embed {
  width: 90vw;
  height: calc(38.0625vw);
  background-color: rgba(0,0,0,0);
}
#wrap .contentsWrap .movieArea.thumb .scroll {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  z-index: 4;
}
#wrap .contentsWrap .movieArea.thumb .scroll .arrow {
  display: inline-block;
  width: 7.5px;
  height: 35.5px;
  background: url("/svg/arrow-down.svg") no-repeat 0 0;
  background-size: auto 35.5px;
}
#wrap .contentsWrap .movieArea.thumb .scroll p.txt {
  font-size: 11px;
  letter-spacing: 0.1em;
  font-weight: bold;
  margin-top: 10px;
}
#spMenu.top .menuOpen span,
#spMenu.credit .menuOpen span {
  background-color: #000;
}
#spMenu.open .menuOpen {
  display: none;
}
#spMenu.open .menuClose {
  display: block;
}
#spMenu.open .spMenuInner {
  left: 0vw;
}
#spMenu .spMenuInner {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  height: 100vh;
  transition: all 0.3s ease 0s;
  background-color: #fff;
  z-index: 10000;
}
#spMenu .menu {
  position: fixed;
  width: 100%;
  z-index: 10001;
}
#spMenu .menuOpen {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 21px;
  right: 22px;
  font-size: 0;
  letter-spacing: 0;
  padding-top: 12px;
  padding-right: 4px;
}
#spMenu .menuOpen span {
  display: block;
  width: 28px;
  height: 1px;
  background-color: #fff;
  transition: color 0.3s ease 0s;
  margin-left: auto;
}
#spMenu .menuOpen span:nth-child(2) {
  width: 19.6px;
}
#spMenu .menuOpen span:nth-child(3) {
  width: 11.2px;
}
#spMenu .menuOpen span:nth-child(n+2) {
  margin-top: 4px;
}
#spMenu .menuClose {
  display: block;
  width: 27px;
  height: 30px;
  position: absolute;
  top: 18px;
  right: 26px;
  display: none;
}
#spMenu .menuClose .l1 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 25px;
  height: 1px;
  background-color: #000;
  display: block;
}
#spMenu .menuClose .l2 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 9px;
  height: 9px;
  margin-top: -4px;
  display: block;
  background: url("/svg/arrow.svg") 100% 50% no-repeat;
  background-size: auto 9px;
}
#spMenu .logo {
  width: 195px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -231px 0 0 -97px;
}
#spMenu .logo img {
  width: 100%;
  height: auto;
}
#spMenu .nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -70px;
}
#spMenu .nav ul li {
  font-family: 'Libre Baskerville', serif;
  font-size: 17.5px;
  line-height: 1.231;
  margin-bottom: 28px;
  text-align: center;
  color: #000;
  position: relative;
}
#spMenu .nav ul li.current:before {
  content: '';
  display: block;
  position: absolute;
  top: 0.5em;
  left: 50%;
  width: 25px;
  height: 1px;
  background-color: #000;
  margin-top: -1px;
}
#spMenu .nav ul li.current:after {
  content: '';
  display: block;
  position: absolute;
  top: 0.5em;
  right: 50%;
  width: 25px;
  height: 1px;
  background-color: #000;
  margin-top: -1px;
}
#spMenu .nav ul li.top:before {
  margin-left: 47px;
}
#spMenu .nav ul li.top:after {
  margin-right: 47px;
}
#spMenu .nav ul li.about:before {
  margin-left: 54px;
}
#spMenu .nav ul li.about:after {
  margin-right: 54px;
}
#spMenu .nav ul li.works:before {
  margin-left: 54px;
}
#spMenu .nav ul li.works:after {
  margin-right: 54px;
}
#spMenu .nav ul li.location:before {
  margin-left: 72px;
}
#spMenu .nav ul li.location:after {
  margin-right: 72px;
}
#spMenu .nav ul li.music:before {
  margin-left: 49px;
}
#spMenu .nav ul li.music:after {
  margin-right: 49px;
}
#spMenu .nav ul li.credit:before {
  margin-left: 55px;
}
#spMenu .nav ul li.credit:after {
  margin-right: 55px;
}
#spMenu .nav ul li a {
  color: #000;
}
#spMenu .sns {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
#spMenu .sns ul {
  position: relative;
  left: 50%;
  float: left;
}
#spMenu .sns ul::after {
  content: "";
  clear: both;
  display: block;
}
#spMenu .sns ul li {
  float: left;
  position: relative;
  left: -50%;
  font-size: 11px;
  line-height: 1.231;
  text-align: center;
  color: #000;
  position: relative;
}
#spMenu .sns ul li.twitter:before {
  content: '/';
  display: block;
  display: inline-block;
  margin-right: 10px;
  padding-left: 10px;
}
#spMenu .sns ul li.twitter:after {
  content: '/';
  display: block;
  display: inline-block;
  margin-left: 10px;
  padding-right: 10px;
}
#spMenu .sns ul li a {
  color: #000;
}
.none {
  display: none;
}
.canvasMovieWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 56.25vw;
  z-index: 0;
  color: #fff;
  background-image: url("/img/canvas_image.png");
  background-size: cover;
  background-position: center center;
}
.page-credit .close .txt .l,
.page-credit .close .txt .r {
  background-color: #000;
}
.page-credit .close .bg circle {
  stroke: #000;
}
.close {
  width: 42px;
  height: 42px;
  position: fixed;
  top: 10px;
  left: 10px;
  display: block;
  cursor: pointer;
}
.close .line {
  display: none;
}
.close .txt {
  position: relative;
  width: 42px;
  height: 42px;
  display: block;
}
.close .txt .l {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -1px;
  width: 19px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, -0.71, 0, 0, 0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transform: matrix3d(0.71, -0.71, 0, 0, 0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.close .txt .r {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -1px;
  width: 19px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, 0.71, 0, 0, -0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transform: matrix3d(0.71, 0.71, 0, 0, -0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.close .bg {
  position: absolute;
  top: 0;
  left: 1px;
  z-index: 1;
}
.close .bg svg {
  width: 42px;
  height: 42px;
}
.close .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
}
#contents .footerArea {
  display: none;
}
#contents .contentsBody {
  background: url("/img/bg_01.png") 0 0 repeat;
}
#contents .headerArea {
  width: 100%;
  height: 56.25vw;
  position: relative;
  display: table;
}
#contents .headerArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .headerArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .headerArea .tit .svg {
  width: auto;
  height: 17px;
  display: inline-block;
}
#contents .headerArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .creditWrap {
  padding-bottom: 70px;
}
#contents .creditWrap .tit {
  padding-top: 100px;
  margin-bottom: 35px;
  text-align: center;
}
#contents .creditWrap .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .creditWrap .tit .svg {
  width: auto;
  height: 17px;
  display: block;
}
#contents .creditWrap .tit .svg svg {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .creditWrap .stit {
  height: 13px;
  margin-top: 70px;
  margin-bottom: 35px;
}
#contents .creditWrap .stit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .creditWrap dl::after {
  content: "";
  clear: both;
  display: block;
}
#contents .creditWrap dl dt {
  width: 48%;
  margin-right: 4%;
  padding-left: 10%;
  clear: left;
  float: left;
  font-size: 10px;
  line-height: 1.231;
  color: #888;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 12px;
  letter-spacing: 2px;
}
#contents .creditWrap dl dd {
  width: 48%;
  padding-right: 10%;
  float: left;
  font-size: 12px;
  line-height: 1.231;
  color: #000;
  margin-bottom: 10px;
}
#contents .creditWrap dl dd .tm {
  font-size: 9px;
  letter-spacing: 1px;
  display: inline-block;
}
#contents .worksDetailWrap {
  background: url("/img/bg_01.png") 0 0 repeat;
}
#contents .worksDetailWrap.file001 .worksDetailHeader {
  background-image: url("/img/works_001_kv_01.jpg");
}
#contents .worksDetailWrap.file001 .groupphoto {
  background-image: url("/img/works_001_photo_05.jpg");
}
#contents .worksDetailWrap.file001 .locationArea {
  background-image: url("/img/works_001_photo_09.jpg");
}
#contents .worksDetailWrap.file001 .worksDetailBody .titWrap .svg svg {
  width: 178.25px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .titWrap .svg.en svg {
  width: 272.5px;
  height: 122px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 677px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 814px;
}
#contents .worksDetailWrap.file002 .worksDetailHeader {
  background-image: url("/img/works_002_kv_01.jpg");
}
#contents .worksDetailWrap.file002 .groupphoto {
  background-image: url("/img/works_002_photo_05.jpg");
}
#contents .worksDetailWrap.file002 .locationArea {
  background-image: url("/img/works_002_photo_09.jpg");
}
#contents .worksDetailWrap.file002 .worksDetailBody .titWrap .svg svg {
  width: 256.09px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .titWrap .svg.en svg {
  width: 287.5px;
  height: 176px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1197px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1436px;
}
#contents .worksDetailWrap.file003 .worksDetailHeader {
  background-image: url("/img/works_003_kv_01.jpg");
}
#contents .worksDetailWrap.file003 .groupphoto {
  background-image: url("/img/works_003_photo_05.jpg");
}
#contents .worksDetailWrap.file003 .locationArea {
  background-image: url("/img/works_003_photo_09.jpg");
}
#contents .worksDetailWrap.file003 .worksDetailBody .titWrap .svg svg {
  width: 345.59px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .titWrap .svg.en svg {
  width: 315.28px;
  height: 122px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1095.11px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1255.42px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .titWrap .svg svg {
  width: 248.66px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .titWrap .svg.en svg {
  width: 311.1px;
  height: 122px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1489px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1766px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .titWrap .svg svg {
  width: 106.1px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .titWrap .svg.en svg {
  width: 229.8px;
  height: 122px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1016px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1234.5px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .titWrap .svg svg {
  width: 310.2px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .titWrap .svg.en svg {
  width: 272.2px;
  height: 231px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1106.5px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1384px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .titWrap .svg svg {
  width: 124.66px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .titWrap .svg.en svg {
  width: 310.86px;
  height: 122px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 1102.8px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1457.27px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .titWrap .svg svg {
  width: 142.67px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .titWrap .svg.en svg {
  width: 254.55px;
  height: 176px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 771.48px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 832.05px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .titWrap .svg svg {
  width: 169.5px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .titWrap .svg.en svg {
  width: 191.89px;
  height: 122px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 913.27px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1069.64px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .titWrap .svg svg {
  width: 308.48px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .titWrap .svg.en svg {
  width: 316.42px;
  height: 122px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .scriptWrap .svg svg {
  width: 307.5px;
  height: 968.61px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .scriptWrap .svg.en svg {
  width: 307.5px;
  height: 1069.88px;
}
#contents .worksDetailWrap.file004 .worksDetailHeader,
#contents .worksDetailWrap.file005 .worksDetailHeader,
#contents .worksDetailWrap.file006 .worksDetailHeader,
#contents .worksDetailWrap.file007 .worksDetailHeader,
#contents .worksDetailWrap.file008 .worksDetailHeader,
#contents .worksDetailWrap.file009 .worksDetailHeader,
#contents .worksDetailWrap.file010 .worksDetailHeader {
  background-image: url("/img/works_004_kv_01.jpg");
}
#contents .worksDetailWrap .worksDetailHeader {
  width: 100%;
  height: 56.25vw;
  position: relative;
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}
#contents .worksDetailWrap .worksDetailHeader .no {
  position: absolute;
  top: 0;
  left: 6%;
}
#contents .worksDetailWrap .worksDetailHeader .no .svg {
  display: block;
  width: auto;
  height: 81px;
}
#contents .worksDetailWrap .worksDetailHeader .no .svg svg {
  width: auto;
  height: 100%;
}
#contents .worksDetailWrap .worksDetailBody {
  z-index: 4;
}
#contents .worksDetailWrap .worksDetailBody .writer {
  text-align: center;
  padding-bottom: 90px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit {
  margin-top: 90px;
  margin-bottom: 34px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .svg {
  width: auto;
  height: 14px;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .svg svg {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksDetailWrap .worksDetailBody .writer .photo {
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: 33px;
}
#contents .worksDetailWrap .worksDetailBody .writer .photo img {
  width: 100%;
  height: auto;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .worksDetailBody .writer .job {
  font-size: 8px;
  line-height: 1.231;
  color: #888;
  margin-bottom: 13px;
  font-weight: 400;
}
#contents .worksDetailWrap .worksDetailBody .writer .name {
  height: 13px;
}
#contents .worksDetailWrap .worksDetailBody .writer .name img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
#contents .worksDetailWrap .worksDetailBody .titWrap {
  position: relative;
  margin-bottom: 5px;
  top: -65px;
  padding-left: 9%;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg {
  width: auto;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg {
  width: auto;
  height: 122px;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg path {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg rect {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap {
  padding-left: 9%;
  padding-right: 9%;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg {
  width: 100%;
  max-width: 340px;
  height: auto;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg {
  width: 100%;
  height: auto;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg path {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg rect {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .img01,
#contents .worksDetailWrap .worksDetailBody .img02,
#contents .worksDetailWrap .worksDetailBody .img03 {
  display: none;
}
#contents .worksDetailWrap .memberArea {
  padding-top: 90px;
  z-index: 1;
}
#contents .worksDetailWrap .memberArea .tit {
  margin-bottom: 35px;
}
#contents .worksDetailWrap .memberArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .memberArea .tit .svg {
  width: auto;
  height: 14px;
  display: block;
}
#contents .worksDetailWrap .memberArea .tit .svg svg {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksDetailWrap .memberArea .groupphoto {
  width: 100%;
  height: 62.5vw;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 70px;
}
#contents .worksDetailWrap .memberArea .stit {
  display: block;
  height: 13px;
  margin-top: 70px;
  margin-bottom: 35px;
}
#contents .worksDetailWrap .memberArea .stit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksDetailWrap .memberArea .portlate {
  margin-bottom: 26px;
}
#contents .worksDetailWrap .memberArea .portlate::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .memberArea .portlate .left {
  width: 50%;
  float: left;
}
#contents .worksDetailWrap .memberArea .portlate .left img {
  width: 100%;
  height: auto;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .memberArea .portlate .right {
  width: 50%;
  float: right;
  padding-top: 33.3333%;
  position: relative;
}
#contents .worksDetailWrap .memberArea .portlate .right .nameWrap {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 30px;
  margin-top: -15px;
  text-align: center;
}
#contents .worksDetailWrap .memberArea .portlate .right .job {
  font-size: 8px;
  line-height: 1.231;
  color: #888;
  margin-bottom: 10px;
  font-weight: 400;
}
#contents .worksDetailWrap .memberArea .portlate .right .name.en {
  height: 9.5px;
}
#contents .worksDetailWrap .memberArea .portlate .right .name {
  height: 13px;
}
#contents .worksDetailWrap .memberArea .portlate .right .name img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
#contents .worksDetailWrap .memberArea .creditlist {
  padding-left: 13%;
  padding-right: 13%;
}
#contents .worksDetailWrap .memberArea .creditlist::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .memberArea .creditlist dt {
  width: 48%;
  margin-right: 4%;
  padding-left: 10%;
  clear: left;
  float: left;
  font-size: 10px;
  line-height: 1.231;
  color: #888;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  margin-bottom: 10px;
  min-height: 12px;
  letter-spacing: 2px;
}
#contents .worksDetailWrap .memberArea .creditlist dd {
  width: 48%;
  padding-right: 10%;
  float: left;
  font-size: 12px;
  line-height: 1.231;
  color: #fff;
  margin-bottom: 10px;
}
#contents .worksDetailWrap .locationArea {
  margin-top: 70px;
  width: 100%;
  height: 40vw;
  position: relative;
  display: table;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 34px;
}
#contents .worksDetailWrap .locationArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .worksDetailWrap .locationArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .locationArea .tit .svg {
  width: auto;
  height: 14px;
  display: inline-block;
}
#contents .worksDetailWrap .locationArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .worksDetailWrap .mapArea {
  display: none;
}
#contents .worksDetailWrap .infoWrap {
  padding-left: 9%;
  padding-right: 9%;
  padding-bottom: 80px;
}
#contents .worksDetailWrap .infoInner::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .underLine {
  background: url("/img/dotline.png") 0 100% repeat-x;
  margin-bottom: 62px;
  width: 100%;
  height: 1px;
}
#contents .worksDetailWrap .infoArea {
  margin-bottom: 34px;
}
#contents .worksDetailWrap .infoArea::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .infoArea .left {
  width: 32%;
  float: left;
  position: relative;
}
#contents .worksDetailWrap .infoArea .left img {
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  width: 100%;
  height: auto;
}
#contents .worksDetailWrap .infoArea .left .no {
  display: none;
}
#contents .worksDetailWrap .infoArea .right {
  width: 59%;
  float: right;
}
#contents .worksDetailWrap .infoArea .right .tit {
  font-size: 11px;
  line-height: 1.77;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  font-weight: bold;
}
#contents .worksDetailWrap .infoArea .right .txt {
  font-size: 11px;
  line-height: 1.77;
}
#contents .musicWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .musicWrap .readcopyArea {
  position: relative;
  top: -28px;
  margin-left: 2%;
  padding: 44px 7%;
  background-color: #000;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  margin-bottom: 56px;
}
#contents .musicWrap .readcopyArea:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -42px;
  left: 50%;
  height: 52px;
  width: 1px;
  background-color: #fff;
}
#contents .musicWrap .readcopyArea p {
  font-size: 11px;
  line-height: 1.81;
}
#contents .musicWrap .imgArea {
  margin-left: 9%;
  margin-right: 9%;
  margin-bottom: 37px;
}
#contents .musicWrap .imgArea img {
  width: 100%;
  height: auto;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
}
#contents .musicWrap .scriptArea {
  padding-bottom: 80px;
}
#contents .musicWrap .scriptArea .kv {
  width: 100%;
  height: 33.3333vw;
  background: url("/img/music_photo_02.jpg") 50% 50% no-repeat;
  background-size: cover;
}
#contents .musicWrap .scriptArea .titWrap {
  position: relative;
  margin-bottom: 5px;
  top: -65px;
  padding-left: 9%;
}
#contents .musicWrap .scriptArea .titWrap .svg {
  width: auto;
  height: 122px;
  display: block;
}
#contents .musicWrap .scriptArea .titWrap .svg svg {
  width: auto;
  height: 100%;
}
#contents .musicWrap .scriptArea .scriptWrap {
  padding-left: 9%;
  padding-right: 9%;
}
#contents .musicWrap .scriptArea .scriptWrap .svg {
  width: 407px;
  height: auto;
  display: block;
}
#contents .musicWrap .scriptArea .scriptWrap .svg svg {
  width: 100%;
  height: auto;
}
#contents .musicWrap .scriptArea .img01,
#contents .musicWrap .scriptArea .img02,
#contents .musicWrap .scriptArea .img03 {
  display: none;
}
#contents .musicWrap .middleHeadArea {
  width: 100%;
  height: 33.3333vw;
  position: relative;
  display: table;
  background: url("/img/music_photo_05.jpg") 50% 50% no-repeat;
  background-size: cover;
  margin-bottom: 35px;
}
#contents .musicWrap .middleHeadArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .musicWrap .middleHeadArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .musicWrap .middleHeadArea .tit .svg {
  width: auto;
  height: 14px;
  display: inline-block;
}
#contents .musicWrap .middleHeadArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .musicWrap .discographyArea {
  padding-bottom: 40px;
}
#contents .musicWrap .discographyArea .txt01 {
  font-size: 11px;
  line-height: 1.81;
  font-weight: bold;
  padding-left: 9%;
  padding-right: 9%;
}
#contents .musicWrap .discographyArea .txt01:after {
  content: '';
  display: block;
  width: 24px;
  height: 1px;
  margin: 37px 0 26px 0;
  background-color: #fff;
}
#contents .musicWrap .discographyArea .txt02 {
  font-size: 11px;
  line-height: 1.81;
  padding-left: 9%;
  padding-right: 9%;
  margin-bottom: 42px;
}
#contents .musicWrap .discographyArea .img01 {
  padding-left: 20%;
  padding-right: 20%;
  margin-bottom: 40px;
}
#contents .musicWrap .discographyArea .img01 img {
  width: 100%;
  height: auto;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
}
#contents .musicWrap .discographyArea .btn {
  width: 150px;
  height: 150px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}
#contents .musicWrap .discographyArea .btn .underline {
  position: absolute;
  top: 92px;
  left: 50%;
  width: 120px;
  height: 1px;
  background-color: #fff;
  margin-left: -60px;
}
#contents .musicWrap .discographyArea .btn .txt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1em;
  margin-top: -0.5em;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 1.231;
}
#contents .musicWrap .discographyArea .btn .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#contents .musicWrap .discographyArea .btn .bg svg {
  width: 150px;
  height: 150px;
}
#contents .musicWrap .discographyArea .btn .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 585 585;
}
#contents .locationWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .locationWrap .readcopyArea {
  position: relative;
  top: -28px;
  margin-left: 2%;
  padding: 44px 7%;
  background-color: #000;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  margin-bottom: 56px;
}
#contents .locationWrap .readcopyArea:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -42px;
  left: 50%;
  height: 52px;
  width: 1px;
  background-color: #fff;
}
#contents .locationWrap .readcopyArea p {
  font-size: 11px;
  line-height: 1.81;
}
#contents .locationWrap .mapArea {
  display: none;
}
#contents .locationWrap .infoWrap {
  padding-left: 9%;
  padding-right: 9%;
  padding-bottom: 80px;
}
#contents .locationWrap .infoInner::after {
  content: "";
  clear: both;
  display: block;
}
#contents .locationWrap .underLine {
  background: url("/img/dotline.png") 0 100% repeat-x;
  margin-bottom: 34px;
  width: 100%;
  height: 1px;
}
#contents .locationWrap .infoArea {
  margin-bottom: 34px;
}
#contents .locationWrap .infoArea::after {
  content: "";
  clear: both;
  display: block;
}
#contents .locationWrap .infoArea .left {
  width: 32%;
  float: left;
  position: relative;
}
#contents .locationWrap .infoArea .left img {
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  width: 100%;
  height: auto;
}
#contents .locationWrap .infoArea .left .no {
  display: none;
}
#contents .locationWrap .infoArea .right {
  width: 59%;
  float: right;
}
#contents .locationWrap .infoArea .right .tit {
  font-size: 11px;
  line-height: 1.77;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  font-weight: bold;
}
#contents .locationWrap .infoArea .right .txt {
  font-size: 11px;
  line-height: 1.77;
}
#contents .worksWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .worksWrap .contentsBody .worksList.en .credit {
  line-height: 1.4em;
}
#contents .worksWrap .contentsBody .worksList.en .list.item-no-video .inner {
  margin-top: -44px;
}
#contents .worksWrap .contentsBody .worksList.en .list.item-no-video .inner.twoline {
  margin-top: -51px;
}
#contents .worksWrap .contentsBody .worksList {
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 57px;
}
#contents .worksWrap .contentsBody .worksList::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksWrap .contentsBody .list {
  float: left;
  width: 46%;
  text-align: center;
}
#contents .worksWrap .contentsBody .list:nth-child(2n+1) {
  float: right;
}
#contents .worksWrap .contentsBody .list .no {
  font-size: 8px;
  line-height: 1.231;
  display: block;
  margin: 0 auto;
}
#contents .worksWrap .contentsBody .list .no:after {
  display: block;
  content: '';
  width: 10px;
  height: 1px;
  transform: matrix3d(0.87, -0.5, 0, 0, 0.5, 0.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  margin: 10px auto 14px auto;
  opacity: 0.5;
}
#contents .worksWrap .contentsBody .list .tit.twoline {
  height: 25px;
}
#contents .worksWrap .contentsBody .list .tit {
  height: 12.5px;
  display: block;
  width: 100%;
  margin-bottom: 12px;
}
#contents .worksWrap .contentsBody .list .tit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
  max-width: 90%;
}
#contents .worksWrap .contentsBody .list .credit {
  display: block;
  margin: 0 auto;
  font-size: 9px;
  line-height: 1.231em;
}
#contents .worksWrap .contentsBody .list.item01 {
  margin-top: -18px;
  float: left;
}
#contents .worksWrap .contentsBody .list.item02 {
  margin-top: 156px;
  float: right;
}
#contents .worksWrap .contentsBody .list.item03 {
  float: left;
}
#contents .worksWrap .contentsBody .list.item01,
#contents .worksWrap .contentsBody .list.item02,
#contents .worksWrap .contentsBody .list.item03 {
  margin-bottom: 56px;
}
#contents .worksWrap .contentsBody .list.item01 a,
#contents .worksWrap .contentsBody .list.item02 a,
#contents .worksWrap .contentsBody .list.item03 a {
  color: #fff;
  display: block;
}
#contents .worksWrap .contentsBody .list.item01 .thumb,
#contents .worksWrap .contentsBody .list.item02 .thumb,
#contents .worksWrap .contentsBody .list.item03 .thumb {
  width: 100%;
  height: auto;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  margin-bottom: 24px;
}
#contents .worksWrap .contentsBody .list.item01 .no:after,
#contents .worksWrap .contentsBody .list.item02 .no:after,
#contents .worksWrap .contentsBody .list.item03 .no:after {
  background-color: #fff;
}
#contents .worksWrap .contentsBody .list.item04 {
  clear: both;
}
#contents .worksWrap .contentsBody .list.item-no-video {
  margin-bottom: 24px;
}
#contents .worksWrap .contentsBody .list.item-no-video .back-rect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  background-color: #fff;
}
#contents .worksWrap .contentsBody .list.item-no-video a {
  color: #000;
  width: 100%;
  display: block;
  position: relative;
}
#contents .worksWrap .contentsBody .list.item-no-video a:before {
  content: "";
  display: block;
  padding-top: 100%;
}
#contents .worksWrap .contentsBody .list.item-no-video .inner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -35px;
}
#contents .worksWrap .contentsBody .list.item-no-video .no:after {
  background-color: #000;
}
#contents .aboutWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .aboutWrap .readcopyArea {
  position: relative;
  top: -28px;
  margin-bottom: -28px;
  margin-left: 2%;
  padding: 44px 7%;
  background-color: #000;
  box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
}
#contents .aboutWrap .readcopyArea p {
  font-size: 11px;
  line-height: 1.81;
}
#contents .aboutWrap .contentsArea {
  position7: relative;
  box-sizing: border-box;
}
#contents .aboutWrap .contentsArea .no {
  width: auto;
  height: 105px;
  margin-bottom: 17px;
}
#contents .aboutWrap .contentsArea .no svg {
  width: auto;
  height: 100%;
  display: block;
  margin-left: auto;
}
#contents .aboutWrap .contentsArea .tit {
  font-size: 14px;
  line-height: 1.81;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
}
#contents .aboutWrap .contentsArea .txt {
  font-size: 11px;
  line-height: 1.81;
}
#contents .aboutWrap .contentsArea .line {
  position: absolute;
  top: 11px;
  left: 50%;
  width: 1px;
  height: 52px;
  background-color: #fff;
  margin-top: -26px;
}
#contents .aboutWrap .contentsArea.item01 {
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 84px;
  padding-bottom: 50px;
}
#contents .aboutWrap .contentsArea.item01 .line {
  top: 11px;
}
#contents .aboutWrap .contentsArea.item02 .photo {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #000;
}
#contents .aboutWrap .contentsArea.item02 .photo img {
  margin: auto;
  width: 96%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(1) {
  display: block;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(2) {
  display: none;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(3) {
  display: none;
}
#contents .aboutWrap .contentsArea.item03 {
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 84px;
  padding-bottom: 50px;
}
#contents .aboutWrap .contentsArea.item04 .photo {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #000;
}
#contents .aboutWrap .contentsArea.item04 .photo img {
  margin: auto;
  width: 96%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item05 {
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 84px;
  padding-bottom: 50px;
}
#contents .aboutWrap .contentsArea.item06 {
  padding-bottom: 40px;
  background-color: #000;
}
#contents .aboutWrap .contentsArea.item06 .img .photo {
  padding-top: 40px;
  padding-bottom: 40px;
}
#contents .aboutWrap .contentsArea.item06 .img .photo img {
  margin: auto;
  width: 96%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item06 .btn {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}
#contents .aboutWrap .contentsArea.item06 .btn .underline {
  position: absolute;
  top: 91px;
  left: 50%;
  width: 120px;
  height: 1px;
  background-color: #fff;
  margin-left: -60px;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1em;
  margin-top: -0.5em;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 1.231;
}
#contents .aboutWrap .contentsArea.item06 .btn .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#contents .aboutWrap .contentsArea.item06 .btn .bg svg {
  width: 150px;
  height: 150px;
}
#contents .aboutWrap .contentsArea.item06 .btn .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 585 585;
}
body .back {
  transform: matrix3d(0, -1, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: right bottom 0px;
  display: block;
  width: 120px;
  height: 35px;
  position: absolute;
  top: 12vw;
  right: 0;
  z-index: 5;
}
body .back .txt {
  font-size: 8px;
  line-height: 1.231;
  display: block;
  position: relative;
  text-align: center;
  color: #fff;
}
body .back .line {
  height: 20px;
  width: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
}
