.canvasMovieWrap
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 0
  color: #fff
  background-size: cover
  background-position: center center



#VideoCanvasContainer
  img
    height: 100vh
