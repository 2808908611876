@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 100
  src: url('/fonts/Roboto/Roboto-Thin.ttf')  format('truetype')

  font-family: 'Roboto'
  font-style: normal
  font-weight: 200
  src: url('/fonts/Roboto/Roboto-Light.ttf')  format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  src: url('/fonts/Roboto/Roboto-Regular.ttf')  format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 700
  src: url('/fonts/Roboto/Roboto-Bold.ttf')  format('truetype')

// @font-face
//  font-family: 'YakuHanJP'
//  font-style: normal
//  font-weight: 100
//  src: url(/fonts/YakuHanJP/YakuHanJP-Thin.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Thin.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Thin.eot)

@font-face
  font-family: 'YakuHanJP'
  font-style: normal
  font-weight: 200
  src: url(/fonts/YakuHanJP/YakuHanJP-Light.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Light.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Light.eot)

// @font-face
//  font-family: 'YakuHanJP'
//  font-style: normal
//  font-weight: 300
//  src: url(/fonts/YakuHanJP/YakuHanJP-DemiLight.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-DemiLight.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-DemiLight.eot)

@font-face
  font-family: 'YakuHanJP'
  font-style: normal
  font-weight: 400
  src: url(/fonts/YakuHanJP/YakuHanJP-Regular.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Regular.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Regular.eot)

// @font-face
//  font-family: 'YakuHanJP'
//  font-style: normal
//  font-weight: 500
//  src: url(/fonts/YakuHanJP/YakuHanJP-Medium.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Medium.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Medium.eot)

@font-face
  font-family: 'YakuHanJP'
  font-style: normal
  font-weight: 700
  src: url(/fonts/YakuHanJP/YakuHanJP-Bold.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Bold.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Bold.eot)

// @font-face
//  font-family: 'YakuHanJP'
//  font-style: normal
//  font-weight: 900
//  src: url(/fonts/YakuHanJP/YakuHanJP-Black.woff2) format("woff2"), url(/fonts/YakuHanJP/YakuHanJP-Black.woff) format("woff"), url(/fonts/YakuHanJP/YakuHanJP-Black.eot)

@font-face
  font-family: 'NotoSansJP'
  font-style: normal
  font-weight: 200
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot")
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPLight.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPLight.otf") format("opentype")

@font-face
  font-family: 'NotoSansJP'
  font-style: normal
  font-weight: 400
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot")
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPRegular.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPRegular.otf") format("opentype")

@font-face
  font-family: 'NotoSansJP'
  font-style: normal
  font-weight: 700
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot")
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPBold.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPBold.otf") format("opentype")

@font-face
    font-family: 'Libre Baskerville'
    font-style: normal
    font-weight: 100
    src: url('/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf')  format('truetype')

@font-face
    font-family: 'Libre Baskerville Bold'
    font-style: bold
    font-weight: 600
    src: url('/fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf')  format('truetype')
