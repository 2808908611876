
clearfix()
  &::after
    content: ""
    clear: both
    display: block

floatCenterWrap()
  position: relative
  left: 50%
  float: left


floatCenterInner()
  float: left
  position: relative
  left: -50%

center-child()
  display: flex
  justify-content: center
  align-items: center

