
.canvasMovieWrap
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 56.25vw
  z-index: 0
  color: #fff
  background-image: url("/img/canvas_image.png")
  background-size: cover
  background-position: center center

.page-credit
  .close
    .txt
      .l, .r
        background-color: #000
    .bg
      circle
        stroke: #000
  // display: none
.close
  width: 42px
  height: 42px
  position: fixed
  top: 10px
  left: 10%px
  display: block
  cursor: pointer

  .line
    display: none

  .txt
    position: relative
    width: 42px
    height: 42px
    display: block

    .l
      position: absolute
      top: 50%
      left: 50%
      margin-left: -9px
      margin-top: -1px
      width: 19px
      height: 1px
      background-color: #fff
      transform: matrix3d(0.71,-0.71,0.00,0,0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
      -webkit-transform: matrix3d(0.71,-0.71,0.00,0,0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)

    .r
      position: absolute
      top: 50%
      left: 50%
      margin-left: -9px
      margin-top: -1px
      width: 19px
      height: 1px
      background-color: #fff
      transform: matrix3d(0.71,0.71,0.00,0,-0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
      -webkit-transform: matrix3d(0.71,0.71,0.00,0,-0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)

  .bg
    position: absolute
    top: 0
    left: 1px
    z-index: 1

    svg
      width: 42px
      height: 42px


    circle
      fill: transparent
      stroke: #fff
      stroke-width: 0.5
      stroke-dasharray: 377 377


#contents

  .footerArea
    display: none




  .contentsBody
    background: url(/img/bg_01.png) 0 0 repeat


  .headerArea
    width: 100%
    height: 56.25vw
    position: relative
    display: table

    .tit
      display: table-cell
      vertical-align: middle
      text-align: center

      .txt
        display: block
        position: absolute
        left: -9999px

      .svg
        width: auto
        height: 17px
        display: inline-block

        svg
          width: auto
          height: 100%


  .creditWrap
    padding-bottom: 70px

    .tit
      padding-top: 100px
      margin-bottom: 35px
      text-align: center

      .txt
        display: block
        position: absolute
        left: -9999px

      .svg
        width: auto
        height: 17px
        display: block

        svg
          width: auto
          height: 100%
          display: block
          margin: 0 auto

    .stit
      height: 13px
      margin-top: 70px
      margin-bottom: 35px

      img
        width: auto
        height: 100%
        display: block
        margin: 0 auto

    dl
      clearfix()

      dt
        width: floor(percentage(182 / 375))
        margin-right: floor(percentage(18 / 375))
        padding-left: 10%
        clear: left
        float: left
        font-size: 10px
        line-height: 1.231
        color: #888
        font-weight: 400
        text-align: right
        text-transform: uppercase
        margin-bottom: 10px
        min-height: 12px
        letter-spacing: 2px

      dd
        width: floor(percentage(182 / 375))
        padding-right: 10%
        float: left
        font-size: 12px
        line-height: 1.231
        color: #000
        margin-bottom: 10px

        .tm
          font-size: 9px
          letter-spacing: 1px
          display: inline-block


  .worksDetailWrap
    background: url(/img/bg_01.png) 0 0 repeat




    &.file001
      .worksDetailHeader
        background-image: url('/img/works_001_kv_01.jpg')

      .groupphoto
        background-image: url('/img/works_001_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_001_photo_09.jpg')
      .worksDetailBody .titWrap .svg svg
        width: 178.25px
      .worksDetailBody .titWrap .svg.en svg
        width: 272.5px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 677px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 814px




    &.file002
      .worksDetailHeader
        background-image: url('/img/works_002_kv_01.jpg')

      .groupphoto
        background-image: url('/img/works_002_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_002_photo_09.jpg')
      .worksDetailBody .titWrap .svg svg
        width: 256.09px
      .worksDetailBody .titWrap .svg.en svg
        width: 287.5px
        height: 176px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1197px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1436px

    &.file003
      .worksDetailHeader
        background-image: url('/img/works_003_kv_01.jpg')

      .groupphoto
        background-image: url('/img/works_003_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_003_photo_09.jpg')

      .worksDetailBody .titWrap .svg svg
        width: 345.59px
      .worksDetailBody .titWrap .svg.en svg
        width: 315.28px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1095.11px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1255.42px
    &.file004
      .worksDetailBody .titWrap .svg svg
        width: 248.66px
      .worksDetailBody .titWrap .svg.en svg
        width: 311.1px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1489px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1766px
    &.file005
      .worksDetailBody .titWrap .svg svg
        width: 106.1px
      .worksDetailBody .titWrap .svg.en svg
        width: 229.8px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1016px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1234.5px
    &.file006
      .worksDetailBody .titWrap .svg svg
        width: 310.2px
      .worksDetailBody .titWrap .svg.en svg
        width: 272.2px
        height: 231px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1106.5px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1384px
    &.file007
      .worksDetailBody .titWrap .svg svg
        width: 124.66px
      .worksDetailBody .titWrap .svg.en svg
        width: 310.86px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 1102.8px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1457.27px
    &.file008
      .worksDetailBody .titWrap .svg svg
        width: 142.67px
      .worksDetailBody .titWrap .svg.en svg
        width: 254.55px
        height: 176px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 771.48px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 832.05px
    &.file009
      .worksDetailBody .titWrap .svg svg
        width: 169.5px
      .worksDetailBody .titWrap .svg.en svg
        width: 191.89px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 913.27px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1069.64px
    &.file010
      .worksDetailBody .titWrap .svg svg
        width: 308.48px
      .worksDetailBody .titWrap .svg.en svg
        width: 316.42px
        height: 122px
      .worksDetailBody .scriptWrap .svg svg
        width: 307.5px
        height: 968.61px
      .worksDetailBody .scriptWrap .svg.en svg
        width: 307.5px
        height: 1069.88px


    &.file004, &.file005, &.file006, &.file007, &.file008, &.file009, &.file010
      .worksDetailHeader
        background-image: url('/img/works_004_kv_01.jpg')



    .worksDetailHeader
      width: 100%
      height: 56.25vw
      position: relative
      display: block
      background-position: 50% 50%
      background-repeat: no-repeat
      background-size: cover
      z-index: 3

      .no
        position: absolute
        top: 0
        left: floor(percentage(25 / 375))

        .svg
          display: block
          width: auto
          height: 81px

          svg
            width: auto
            height: 100%



    .worksDetailBody
      z-index: 4

      .writer
        text-align: center
        padding-bottom: 90px

        .tit
          margin-top: 90px
          margin-bottom: 34px

          .txt
            display: block
            position: absolute
            left: -9999px

          .svg
            width: auto
            height: 14px
            display: block

            svg
              width: auto
              height: 100%
              display: block
              margin: 0 auto

        .photo
          padding-left: floor(percentage(95 / 375))
          padding-right: floor(percentage(95 / 375))
          margin-bottom: 33px

          img
            width: 100%
            height: auto
            box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)

        .job
          font-size: 8px
          line-height: 1.231
          color: #888
          margin-bottom: 13px
          font-weight: 400

        .name
          height: 13px

          img
            width: auto
            height: 100%
            margin: 0 auto


      .titWrap
        position: relative
        margin-bottom: 5px
        top: -65px
        padding-left: floor(percentage(35 / 375))

        .svg
          width: auto
          // height: 122px
          display: block

          svg
            width: auto
            height: 122px

            path
              fill: #fff
            rect
              fill: #fff

      .scriptWrap
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))


        .svg
          // width: 306px
          width: 100%
          max-width: 340px
          height: auto
          display: block

          svg
            width: 100%
            height: auto
            path
              fill: #fff
            rect
              fill: #fff

      .img01, .img02, .img03
        display: none

    .memberArea
      padding-top: 90px
      z-index: 1

      .tit
        margin-bottom: 35px

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: auto
          height: 14px
          display: block

          svg
            width: auto
            height: 100%
            display: block
            margin: 0 auto

      .groupphoto
        width: 100%
        height: 62.5vw
        background-position: 50% 50%
        background-repeat: no-repeat
        background-size: cover
        margin-bottom: 70px

      .stit
        display: block
        height: 13px
        margin-top: 70px
        margin-bottom: 35px

        img
          width: auto
          height: 100%
          display: block
          margin: 0 auto

      .portlate
        clearfix()
        margin-bottom: 26px

        .left
          width: 50%
          float: left

          img
            width: 100%
            height: auto
            box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)

        .right
          width: 50%
          float: right
          padding-top: 33.3333%
          position: relative

          .nameWrap
            position: absolute
            top: 50%
            left: 0
            width: 100%
            height: 30px
            margin-top: -15px
            text-align: center

          .job
            font-size: 8px
            line-height: 1.231
            color: #888
            margin-bottom: 10px
            font-weight: 400

          .name.en
            height: 9.5px
          .name
            height: 13px

            img
              width: auto
              height: 100%
              margin: 0 auto

      .creditlist
        padding-left: floor(percentage(52 / 375))
        padding-right: floor(percentage(52 / 375))
        clearfix()

        dt
          width: floor(percentage(182 / 375))
          margin-right: floor(percentage(18 / 375))
          padding-left: 10%
          clear: left
          float: left
          font-size: 10px
          line-height: 1.231
          color: #888
          font-weight: 400
          text-align: right
          text-transform: uppercase
          margin-bottom: 10px
          min-height: 12px
          letter-spacing: 2px

        dd
          width: floor(percentage(182 / 375))
          padding-right: 10%
          float: left
          font-size: 12px
          line-height: 1.231
          color: #fff
          margin-bottom: 10px

    .locationArea
      margin-top: 70px
      width: 100%
      height: 40vw
      position: relative
      display: table
      background-position: 50% 50%
      background-repeat: no-repeat
      background-size: cover
      margin-bottom: 34px

      .tit
        display: table-cell
        vertical-align: middle
        text-align: center

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: auto
          height: 14px
          display: inline-block

          svg
            width: auto
            height: 100%

    .mapArea
      display: none

    .infoWrap
      padding-left: floor(percentage(35 / 375))
      padding-right: floor(percentage(35 / 375))
      padding-bottom: 80px

    .infoInner
      clearfix()

    .underLine
      background: url('/img/dotline.png') 0 100% repeat-x
      margin-bottom: 62px
      width: 100%
      height: 1px

    .infoArea
      clearfix()
      margin-bottom: 34px

      .left
        width: floor(percentage(100 / 306))
        float: left
        position: relative

        img
          box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
          width: 100%
          height: auto

        .no
          display: none

      .right
        width: floor(percentage(183 / 306))
        float: right

        .tit
          font-size: 11px
          line-height: 1.77
          margin-bottom: 12px
          text-transform: uppercase
          letter-spacing: 3.5px
          font-weight: bold

        .txt
          font-size: 11px
          line-height: 1.77


  .musicWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: relative
      top: -28px
      margin-left: floor(percentage(8 / 375))
      // width: floor(percentage(507 / 375))
      padding: 44px floor(percentage(27 / 361))
      background-color: #000
      box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
      box-sizing: border-box
      margin-bottom: 56px

      &:after
        content: ''
        display: block
        position: absolute
        bottom: -42px
        left: 50%
        height: 52px
        width: 1px
        background-color: #fff

      p
        font-size: 11px
        line-height: 1.81

    .imgArea
      margin-left: floor(percentage(35 / 375))
      margin-right: floor(percentage(35 / 375))
      margin-bottom: 37px

      img
        width: 100%
        height: auto
        box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)

    .scriptArea
      padding-bottom: 80px

      .kv
        width: 100%
        height: 33.3333vw
        background: url('/img/music_photo_02.jpg') 50% 50% no-repeat
        // background-size: 100% auto
        background-size: cover

      .titWrap
        position: relative
        margin-bottom: 5px
        top: -65px
        padding-left: floor(percentage(35 / 375))

        .svg
          width: auto
          height: 122px
          display: block

          svg
            width: auto
            height: 100%

      .scriptWrap
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))

        .svg
          width: 407px
          // width: 100%
          // max-width: 340px
          height: auto
          display: block

          svg
            width: 100%
            height: auto

      .img01, .img02, .img03
        display: none

    .middleHeadArea
      width: 100%
      height: 33.3333vw
      position: relative
      display: table
      background: url('/img/music_photo_05.jpg') 50% 50% no-repeat
      background-size: cover
      margin-bottom: 35px

      .tit
        display: table-cell
        vertical-align: middle
        text-align: center

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: auto
          height: 14px
          display: inline-block

          svg
            width: auto
            height: 100%

    .discographyArea
      padding-bottom: 40px

      .txt01
        font-size: 11px
        line-height: 1.81
        font-weight: bold
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))

        &:after
          content: ''
          display: block
          width: 24px
          height: 1px
          margin: 37px 0 26px 0
          background-color: #fff

      .txt02
        font-size: 11px
        line-height: 1.81
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))
        margin-bottom: 42px

      .img01
        padding-left: floor(percentage(78 / 375))
        padding-right: floor(percentage(78 / 375))
        margin-bottom: 40px

        img
          width: 100%
          height: auto
          box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)

      .btn
        width: 150px
        height: 150px
        display: block
        cursor: pointer
        margin: 0 auto
        position: relative

        .underline
          position: absolute
          top: 92px
          left: 50%
          width: 120px
          height: 1px
          background-color: #fff
          margin-left: -60px

        .txt
          position: absolute
          top: 50%
          left: 0
          width: 100%
          height: 1em
          margin-top: -0.5em
          display: block
          color: #fff
          text-align: center
          font-size: 12px
          line-height: 1.231

        .bg
          position: absolute
          top: 0
          left: 0
          z-index: 1

          svg
            width: 150px
            height: 150px

          circle
            fill: transparent
            stroke: #fff
            stroke-width: 0.5
            stroke-dasharray: 585 585


  .locationWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: relative
      top: -28px
      margin-left: floor(percentage(8 / 375))
      // width: floor(percentage(507 / 375))
      padding: 44px floor(percentage(27 / 361))
      background-color: #000
      box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
      box-sizing: border-box
      margin-bottom: 56px

      &:after
        content: ''
        display: block
        position: absolute
        bottom: -42px
        left: 50%
        height: 52px
        width: 1px
        background-color: #fff

      p
        font-size: 11px
        line-height: 1.81

    .mapArea
      display: none

    .infoWrap
      padding-left: floor(percentage(35 / 375))
      padding-right: floor(percentage(35 / 375))
      padding-bottom: 80px

    .infoInner
      clearfix()

    .underLine
      background: url('/img/dotline.png') 0 100% repeat-x
      margin-bottom: 34px
      width: 100%
      height: 1px

    .infoArea
      clearfix()
      margin-bottom: 34px

      .left
        width: floor(percentage(100 / 306))
        float: left
        position: relative

        img
          box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
          width: 100%
          height: auto

        .no
          display: none

      .right
        width: floor(percentage(183 / 306))
        float: right

        .tit
          font-size: 11px
          line-height: 1.77
          margin-bottom: 12px
          text-transform: uppercase
          letter-spacing: 3.5px
          font-weight: bold

        .txt
          font-size: 11px
          line-height: 1.77


  .worksWrap

    .contentsBody
      padding-bottom: 1px
      .worksList.en
        .credit
          line-height: 1.4em

        .list

          &.item-no-video
            .inner
              margin-top: -44px
            .inner.twoline
              margin-top: -51px

      .worksList
        clearfix()
        padding-left: floor(percentage(30 / 375))
        padding-right: floor(percentage(30 / 375))
        padding-bottom: 57px

      .list
        float: left
        width: floor(percentage(146 / 315))
        text-align: center

        &:nth-child(2n+1)
          float: right

        .no
          font-size: 8px
          line-height: 1.231
          display: block
          margin: 0 auto

          &:after
            display: block
            content: ''
            width: 10px
            height: 1px
            transform: matrix3d(0.87,-0.5,0.00,0,0.50,0.87,0.00,0,0,0,1,0,0,0,0,1)
            margin: 10px auto 14px auto
            opacity: 0.5

        .tit.twoline
          height: 25px
        .tit
          height: 12.5px
          display: block
          width: 100%
          margin-bottom: 12px

          img
            width: auto
            height: 100%
            display: block
            margin: 0 auto
            max-width: 90%

        .credit
          display: block
          margin: 0 auto
          font-size: 9px
          line-height: 1.231em

        &.item01
          margin-top: -18px
          float left

        &.item02
          margin-top: 156px
          float: right

        &.item03
          float left

        &.item01, &.item02, &.item03
          margin-bottom: 56px

          a
            color: #fff
            display: block

          .thumb
            width: 100%
            height: auto
            box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
            margin-bottom: 24px
          .no
            &:after
              background-color: #fff

        &.item04
          clear: both

        &.item-no-video
          margin-bottom: 24px
          .back-rect
            position: absolute
            top: 0
            left: 0
            width: 100%
            height 100%
            box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
            background-color: #fff

          a
            color: #000
            width: 100%
            display: block
            position: relative

            &:before
              content:""
              display: block
              padding-top: 100%

          .inner
            position: absolute
            top: 50%
            left: 0
            right: 0
            margin-top: -35px

          .no
            &:after
              background-color: #000


  .aboutWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: relative
      top: -28px
      margin-bottom: -28px
      margin-left: floor(percentage(8 / 375))
      // width: floor(percentage(507 / 375))
      padding: 44px floor(percentage(27 / 361))
      background-color: #000
      box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2)
      box-sizing: border-box

      p
        font-size: 11px
        line-height: 1.81

    .contentsArea
      position7: relative
      box-sizing: border-box

      .no
        width: auto
        height: 105px
        margin-bottom: 17px

        svg
          width: auto
          height: 100%
          display: block
          margin-left: auto

      .tit
        font-size: 14px
        line-height: 1.81
        margin-bottom: 25px
        text-transform: uppercase
        letter-spacing: 3.5px

      .txt
        font-size: 11px
        line-height: 1.81

      .line
        position: absolute
        top: 11px
        left: 50%
        width: 1px
        height: 52px
        background-color: #fff
        margin-top: -26px

      &.item01
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))
        padding-top: 84px
        padding-bottom: 50px

        .line
          top: 11px

      &.item02

        .photo
          padding-top: 40px
          padding-bottom: 40px
          background-color: #000

          img
            margin: auto
            width: 100 - floor(percentage(16 / 375))
            height: auto

          &:nth-child(1)
            display: block

          &:nth-child(2)
            display: none

          &:nth-child(3)
            display: none

      &.item03
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))
        padding-top: 84px
        padding-bottom: 50px

      &.item04

        .photo
          padding-top: 40px
          padding-bottom: 40px
          background-color: #000

          img
            margin: auto
            width: 100 - floor(percentage(16 / 375))
            height: auto

      &.item05
        padding-left: floor(percentage(35 / 375))
        padding-right: floor(percentage(35 / 375))
        padding-top: 84px
        padding-bottom: 50px

      &.item06
        padding-bottom: 40px
        background-color: #000

        .img
          .photo
            padding-top: 40px
            padding-bottom: 40px

            img
              margin: auto
              width: 100 - floor(percentage(16 / 375))
              height: auto

        .btn
          width: 150px
          height: 150px
          margin: 0 auto
          display: block
          cursor: pointer

          .underline
            position: absolute
            top: 91px
            left: 50%
            width: 120px
            height: 1px
            background-color: #fff
            margin-left: -60px

          .txt
            position: absolute
            top: 50%
            left: 0
            width: 100%
            height: 1em
            margin-top: -0.5em
            display: block
            color: #fff
            text-align: center
            font-size: 12px
            line-height: 1.231

          .bg
            position: absolute
            top: 0
            left: 0
            z-index: 1

            svg
              width: 150px
              height: 150px

            circle
              fill: transparent
              stroke: #fff
              stroke-width: 0.5
              stroke-dasharray: 585 585


body
  .back
    transform: matrix3d(0,-1,0.00,0,1.00,0,0.00,0,0,0,1,0,0,0,0,1)
    transform-origin: right bottom 0px
    display: block
    width: 120px
    height: 35px
    position: absolute
    top: 12vw
    right: 0
    z-index: 5

    .txt
      font-size: 8px
      line-height: 1.231
      display: block
      position: relative
      text-align: center
      color: #fff

    .line
      height: 20px
      width: 1px
      background-color: #fff
      position: absolute
      bottom: 0
      left: 50%
      display: block
