
@import "./utils/_reset"
@import "./utils/_mixins"
@import "./utils/_animation"
@import "./utils/_keyframes"
@import "./utils/_fonts"

$font= 'Roboto', 'YakuHanJP', 'NotoSansJP', sans-serif
$font_serif= 'Libre Baskerville', serif

.pc
  display: none !important

body,html
  font-family: $font
  font-weight: 100
  width: 100%
  height: 100%

body
  position: relative
  outline: none
  color: #000
  background: #fff
  text-align: left
  line-height: 1.231
  -webkit-text-size-adjust: none
  letter-spacing: 2.5px


#loading
  position: fixed
  width: 100%
  height: 100%
  top:0
  left: 0
  background: #fff
  z-index: 9999999
  opacity: 1

  .line
    width: 0%
    height: 2px
    background-color: #ccc
    top: calc(50% - 1px)
  .line.auto
    width: 50%
    widthanim(0%, 50%, 'loading')
    animationParams(widthanimloading, 3s, linear)

body.no-gl
  #wrap
    .page-container.page-top
      background-color: #fff
      // .enterWrap
      //   top: 0
      //   margin-top: 0
      .enter
        display: none

      $diff = 90px
      // .logo
      //   margin-top: (-204px - $diff)
      // .read
      //   margin-top: (145px - $diff)
      nav.nav
        width: (654px / 2)
        height: 45px
        border-top: 2px solid #ccc
        border-bottom: 2px solid #ccc
        display: block
        top: 50%
        left: 50%
        padding: 0 22px
        margin: 70px auto 0
        ul

          li
            display: inline-block
            line-height: 42px
            font-size: -1
            letter-spacing: 0em
            a
              letter-spacing: 2.5px
              color: #000
              font-size: 12px
              font-weight: 400
          li:nth-child(n+2)
            margin-left: 10px

      .nogl-description
        display: block
        position: fixed
        height: 66.5px
        width: 100%
        background: #e5e5e5
        bottom: 0
        text-align: center
        p
          font-size: 13px
          line-height: 18px
          margin-top: 16px
          // line-height: 55px
          color: #888




@import "./components/_movieCanvas"

#Video2
  position: fixed
  top: 0
  left: 0
  z-index: 999999
  width: 100%
#wrap
  .chapter
    .contentsWrap
      .seek
        opacity: 0
      .switch
        opacity: 0
        display: none
      .backbutton
        display: none
        opacity: 0
  .offshot
    .contentsWrap
      .switch
        opacity: 0
        display: none
      .seek
        opacity: 0
#wrap
  .page-container.page-top
    position: fixed
  .page-container.page-top.enter
    overflow: hidden
  .page-container
    -webkit-overflow-scrolling: touch
#wrap
  position: relative
  background-color: #ccc
  color: #fff
  width: 100%
  height: 100%
  a
    color: #fff
  #pageWrap
    width: 100%
    height: 100%
  .page-container
    width: 100%
    min-height: 100%
    overflow-x: hidden
  .enterWrap
    position: absolute
    top: 50%
    margin-top: -210px
    left: 0
    z-index: 10
    width: 100%
    height: 100%
    .nogl-description
      display: none
    nav.nav
      display: none

    .logo
      // background: url("/svg/logo_01.svg") no-repeat 0 0
      // background-size: 100% auto
      position: relative
      text-indent: -9999px
      width: (619px / 2)
      height: (383px / 2)
      margin: 0 auto
      letter-spacing: 0
      line-height: 0
      svg
        width: 100%
        height: 100%
        // position: absolute
        // top: 0
        // left: 0
        path
          fill: #333
    .read
      margin: 54px auto 0
      width: 242px
      text-align: center
      color: #000
      letter-spacing: 0.05em
      font-size: 12px

      &:before
        content: ""
        position: absolute
        top: 0.5em
        left: 0
        width: 40px
        height: 1px
        background-color: #000
        display: block

      &:after
        content: ""
        position: absolute
        top: 0.5em
        right: 0
        width: 40px
        height: 1px
        background-color: #000
        display: block

    .enter
      margin: 96px auto 0
      width: 75px
      height: 75px
      cursor: pointer
      position: relative
      center-child()

      .txt
        position: relative
        vertical-align: middle
        text-align: center
        font-size: 12px
        line-height: 1.5
        color: #fff
        z-index: 2
        font-weight: 100
        letter-spacing: 0.06em

      .bg
        position: absolute
        top: 0
        left: 0
        z-index: 1
        svg
          width: 100%
          height: 100%

        circle
          fill: #333
          stroke: #333
          stroke-width: 0.5
          stroke-dasharray: 377 377


  .contentsWrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 0
    color: #000

    .logo
      position: absolute
      top: 25px
      left: 25px
      width: 90px
      height: 46px
      z-index: 100
      text-indent: -9999px
      svg
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        path
          fill: #000

    .switch
      $size = 60px
      position: absolute
      left: 50%
      bottom: 24px
      margin-left: -33px
      z-index: 100

      a
        display: block
        width: $size
        height: $size
        position: relative
        cursor: pointer
        margin: 0 auto
        center-child()
      .txt
        position: relative
        z-index: 2
        width: 18px
        height: 4px

        span
          position: absolute
          background-color: #000
          width: 4px
          height: 4px

        span:nth-of-type(1)
          left: 0px

        span:nth-of-type(2)
          left: 7px

        span:nth-of-type(3)
          left: 14px

      .bg
        position: absolute
        top: 0
        left: 0
        z-index: 1
        svg
          width: 100%
          height: 100%
        circle
          fill: transparent
          stroke-width: 0.5
          stroke-dasharray: 377 377
          stroke: #000

      p
        font-size: 11px
        text-align: center
        font-weight: bold
        margin-top: 8px
        letter-spacing: 0.08em
        transform: scale(0.9)

    $seekTop = calc(50% + 28vw + 27px - 44px)
    .backbutton
      top: $seekTop
      position: absolute
      margin-top: 40px
      width: 115px
      left: 50%
      margin-left: -41px
      z-index: 100
      p
        text-align: center
        font-size: 11px
        font-weight: bold

        letter-spacing: 0.08em
        position: relative
        display: inline-block
        width: 82px
        &:after
          content: ""
          position: absolute
          right: -30px
          width: (23px /2)
          height: (25px / 2)
          // background: #000
          background-size: 100%
          background-image: url("/svg/backbutton-arrow.svg")

    .seek
      position: absolute
      left: 10%
      top: $seekTop
      width: 80%
      height: 1px
      z-index: 10
      pointer-events: none

      .seekbar
        position: absolute
        top: 0
        left: 0
        background-color: #00000030
        width: 100%
        height: 1px

      .line
        position: absolute
        top: 0
        left: 0
        background-color:  #000
        width: 30%
        height: 1px

      .current
        position: absolute
        top: -3px
        left: 30%
        background-color:  #000
        width: 1px
        height: 7px

    .movieArea
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1

      &.view
        z-index: 2
        color #000
        .movieInner
          width: 100%
          height: 100%
          padding-top: 50vh
          text-align: center
      &.thumb
        color: #000
        text-align: center

        .scroller
          width: 100%
          height: 100%
          position: absolute
          z-index: 5
        a
          color: #000
        $movieTop = calc(50% - 38vw - 23px)
        $movieBottom = calc(50% + 22vw)
        .name
          position: absolute
          top: $movieTop
          left: 0
          width: 100%
          text-align: center
          z-index: 7

          .tit
            font-size: 13px
            .titleInner
              width: 200px
              display: inline-block
            .arrow
              display: inline-block
              width: 15px
              height: 8px
              background: url(/svg/arrow-right.svg) no-repeat 0 0;
              background-size: auto 8px
              margin-left: -15px
              transform: translateX(28px)
          .chapter
            margin-top: 15px
            font-size: 10px
            letter-spacing: 0.1em
            font-weight: bold
            transform: scale(0.9)
        .state
          position: absolute
          top: $movieBottom
          left: 0
          width: 100%
          z-index: 7

          margin-top: 40px
          .location
            font-size: 13px

            .locationInner
              width: 200px
              display: inline-block

            .icon
              display: inline-block;
              width: 23px;
              height: 19px;
              background: url(/img/icon_03.png) no-repeat 0 0;
              background-size: auto 19px;
              margin-bottom: -5px
            .arrow
              display: inline-block
              width: 15px
              height: 8px
              background: url(/svg/arrow-right.svg) no-repeat 0 0;
              background-size: auto 8px
              margin-left: -15px
              transform: translateX(28px)
          .neWrap
            margin-top: 15px
            font-size: 10px
            letter-spacing: 0.1em
            font-weight: bold
            transform: scale(0.9)
            &>*:nth-child(2)
              margin: 0 5px
        .movieInner
          position: absolute
          z-index: 6
          width: 100%
          height: 100%

          $itemHeight = 90vw * 406 / 960
          $itemHeightHalf = $itemHeight / 2 * -1
          .item
            top: 50%
            left: 5%
            width: 90%
            margin-top: "calc(%s)" % $itemHeightHalf
            position: absolute
            .embed

              width: 90vw
              height: "calc(%s)" % $itemHeight
              background-color: rgba(0, 0, 0, 0)

        .scroll
          position: absolute
          bottom: 20px
          width: 100%
          text-align: center
          z-index: 4
          .arrow
            display: inline-block
            width: 7.5px
            height: 35.5px
            background: url(/svg/arrow-down.svg) no-repeat 0 0;
            background-size: auto 35.5px

          p.txt
            font-size: 11px
            letter-spacing: 0.1em
            font-weight: bold
            margin-top: 10px


#spMenu
  &.top, &.credit
    .menuOpen
      span
        background-color: #000
#spMenu
  &.open
    .menuOpen
      display: none
    .menuClose
      display: block
    .spMenuInner
      left: 0vw
      // display: block

  .spMenuInner
    position: fixed
    top: 0
    left: 100vw
    width: 100%
    height: 100vh
    transition: all 0.3s ease 0s
    background-color: #fff
    z-index: 10000
    // display: none

  .menu
    position: fixed
    width: 100%
    z-index: 10001
  .menuOpen
    width: 35px
    height: 35px
    position: absolute
    top: 21px
    right: 22px
    font-size: 0
    letter-spacing: 0
    padding-top: 12px
    padding-right: 4px

    span
      display: block

      width: 28px
      height: 1px
      background-color: #fff
      transition: color 0.3s ease 0s
      margin-left: auto
    span:nth-child(2)
      width: 19.6px

    span:nth-child(3)
      width: 11.2px
    span:nth-child(n+2)
      margin-top: 4px
  .menuClose
    display: block
    width: 27px
    height: 30px
    position: absolute
    top: 18px
    right: 26px
    display: none
    .l1
      position: absolute
      top: 50%
      left: 0
      width: 25px
      height: 1px
      background-color: #000
      display: block

    .l2
      position: absolute
      top: 50%
      right: 0
      width: 9px
      height: 9px
      margin-top: -4px
      display: block
      background: url(/svg/arrow.svg) 100% 50% no-repeat
      background-size: auto 9px

  .logo
    width: 195px
    position: absolute
    top: 50%
    left: 50%
    margin: -231px 0 0 -97px

    img
      width: 100%
      height: auto

  .nav
    position: absolute
    top: 50%
    left: 0
    width: 100%
    margin-top: -70px

    ul
      li
        font-family: $font_serif
        font-size: 17.5px
        line-height: 1.231
        margin-bottom: 28px
        text-align: center
        color: #000
        position: relative

        &.current

          &:before
            content: ''
            display: block
            position: absolute
            top: 0.5em
            left: 50%
            width: 25px
            height: 1px
            background-color: #000
            margin-top: -1px

          &:after
            content: ''
            display: block
            position: absolute
            top: 0.5em
            right: 50%
            width: 25px
            height: 1px
            background-color: #000
            margin-top: -1px

        &.top
          &:before
            margin-left: 47px
          &:after
            margin-right: 47px

        &.about
          &:before
            margin-left: 54px
          &:after
            margin-right: 54px

        &.works
          &:before
            margin-left: 54px
          &:after
            margin-right: 54px

        &.location
          &:before
            margin-left: 72px
          &:after
            margin-right: 72px

        &.music
          &:before
            margin-left: 49px
          &:after
            margin-right: 49px

        &.credit
          &:before
            margin-left: 55px
          &:after
            margin-right: 55px

        a
          color: #000

  .sns
    position: absolute
    bottom: 30px
    left: 0
    width: 100%

    ul
      floatCenterWrap()
      clearfix()

      li
        floatCenterInner()
        font-size: 11px
        line-height: 1.231
        text-align: center
        color: #000
        position: relative

        &.twitter

          &:before
            content: '/'
            display: block
            display: inline-block
            margin-right: 10px
            padding-left: 10px

          &:after
            content: '/'
            display: block
            display: inline-block
            margin-left: 10px
            padding-right: 10px

        a
          color: #000

@import "./utils/_adjust"
@import "./components/_underpageSp"
