html, body {
  border: 0;
  color: black;
  line-height: 1.5;
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

* {
  box-sizing: border-box;
}

div, span, object, iframe, img, table, caption, thead, tbody,
tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu,
nav, footer, header, section, summary, mark, audio, video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
ol, ul, li, fieldset, legend, label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;

}

article, aside, canvas, figure, figure img, figcaption, hgroup,
footer, header, nav, section, audio, video {
  display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  caption, th, td {
    text-align: left;
    vertical-align: middle;
  }
}

a img {
  border: 0;
}

a{
  color: black
}

a:link  {
    text-decoration: none;
}

a:visited   {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active    {
    text-decoration: none;
}


:focus {
  outline: 0;
}

span.upper {
  font-size: 10px;
  vertical-align: super;
}

//------------------------------------------------------------
//  reset
//------------------------------------------------------------


div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
table,
img,
section,
header,
footer,
article,
object,
svg
  position: relative
  backface-visibility: visible
  outline: none
  //-webkit-transform: perspective(0)
  //-webkit-transform-style: preserve-3d

img,
canvas,
object,
svg
  display: block

// ::-webkit-scrollbar
//  -webkit-appearance: none
//  width: 5px
//  height: 5px
//  background-color: #333

// ::-webkit-scrollbar-thumb
//  background-color: #fff

// ::-webkit-scrollbar-thumb:hover
//  background-color: #aaa

// *:selection
//  background: #333
//  color: #fff

// *:-moz-selection
//  background: #333
//  color: #fff

select
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  border-radius: 0
  border: 0
  margin: 0
  padding: 0
  background: none transparent
  vertical-align: middle
  font-size: inherit
  color: inherit
  box-sizing: content-box


hr
  display: none

svg
  overflow: visible
  padding: 1px


  // ::-webkit-scrollbar
  //  width: 2px
  //  height: 2px

a
  color: #000
  text-decoration: none

  &:hover
    color: #000
    text-decoration: none

// *,
// *::before,
// *::after
//  box-sizing: border-box

